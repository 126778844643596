import { zodResolver } from '@hookform/resolvers/zod'
import assert from 'assert'
import { Parser } from 'expr-eval'
import { cloneDeep, isEqual, isNil } from 'lodash'
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import {
  Control,
  FormState,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
  useForm,
  useWatch,
} from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'
import {
  BasicField,
  Condition,
  ConditionTarget,
  ConditionTargetEnableRequire,
  ConditionTargetShowHide,
  ConditionType,
  DoOptionsEnableRequire,
  DoOptionsShowHide,
  DoOptionsSkipPage,
  FieldConditionMap,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  NumericField,
  Page,
  TEMP_PAGE_ID,
} from '../interfaces'
import { ConditionUtils, FormBuilderUtils, GeneralUtils, GroupedPageUtils, ValidationUtils } from '../utils'

interface FormStateContextType {
  pages: Page[]
  setPages: (pages: Page[]) => void
  fieldPagesSOT: FormFieldPages
  setFieldPagesSOT: (fieldPages: FormFieldPages) => void
  fieldPages: FormFieldPages
  setFieldPages: (fieldPages: FormFieldPages) => void
  formFields: FormField[]
  setFormFields: (formFields: FormField[]) => void
  latestFormData: { [key: string]: any }
  page?: string
  setPage: (page: string) => void
  fieldConditionMap: FieldConditionMap
  setFieldConditionMap: (fieldConditionMap: FieldConditionMap) => void
  conditions: Condition[]
  setConditions: (conditions: Condition[]) => void
  evaluatePageConditions: (newPage: string) => { nextPage: string; latestPages?: FormFieldPages }
  hiddenPageIds: string[]
  isPrintMode: boolean
  setIsPrintMode: (isPrintMode: boolean) => void
  isReadOnly: boolean
  setIsReadOnly: (isReadOnly: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  /**
   * React hook form methods
   */
  handleSubmit: UseFormHandleSubmit<any, any>
  control: Control
  formState: FormState<any>
  unregister: UseFormUnregister<any>
  getValues: UseFormGetValues<any>
  trigger: UseFormTrigger<any>
  clearErrors: UseFormClearErrors<any>
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>
  onAddEntryToGroupedPage: (
    activePage: Page,
    latestFieldPages: FormFieldPages | null,
    listIndex?: number,
  ) => FormFieldPages
  onRemoveEntryFromGroupedPage: (activePage: Page, index: number) => void
}

const FormStateContext = createContext<FormStateContextType | undefined>(undefined)

export const FormStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [fieldPages, setFieldPages] = useState<FormFieldPages>({})
  const [fieldPagesSOT, setFieldPagesSOT] = useState<FormFieldPages>({}) // Source of truth. Should not be modified.
  const [formFields, setFormFields] = useState<FormField[]>([])
  const [page, setPage] = useState<Page['guid']>()
  const [pages, setPages] = useState<Page[]>([])
  const [isPrintMode, setIsPrintMode] = useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const [previousConditionKeys, setPreviousConditionKeys] = useState<string[]>([])
  const [fieldConditionMap, setFieldConditionMap] = useState<FieldConditionMap>({})
  const [conditions, setConditions] = useState<Condition[]>([])
  const [previousValues, setPreviousValues] = useState()
  const [hiddenPageIds, setHiddenPageIds] = useState<string[]>([])
  const [previousFields, setPreviousFields] = useState<FormField[]>([])
  const [newFields, setNewFields] = useState<FormField[]>([])
  const [formulaFieldsGuids, setFormulaFieldsGuids] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const conditionMapKeys = Object.keys(fieldConditionMap)
  const sortedPages = GeneralUtils.sortPages(pages)

  /**
   * Since setState() is async, we need to keep a local reference of
   * the fieldPages state when doing condition checking.
   */
  let fieldPagesLocalRef = fieldPages

  const zodValidationSchema = z.object(ValidationUtils.initializeValidationSchema(formFields))
  const { handleSubmit, control, formState, unregister, getValues, trigger, setValue, watch, clearErrors } = useForm({
    resolver: isReadOnly ? undefined : zodResolver(zodValidationSchema),
    defaultValues: {},
  })

  const latestFormData = watch()
  const conditionSources = watch(Object.keys(fieldConditionMap) as any)
  const referencedFormulaFieldsValues = useWatch({
    name: formulaFieldsGuids as any,
    control,
  })

  const onUpdateFormStructure = (updatedPages: FormFieldPages) => {
    fieldPagesLocalRef = updatedPages
    setFieldPages(updatedPages)
    page && setFormFields(updatedPages[page] ?? [])
  }

  const triggerConditionChecking = (formFieldId: string) => {
    const fieldConditions = fieldConditionMap[formFieldId]

    if (!fieldConditions) {
      return
    }

    fieldConditions.forEach(condition => {
      let isConditionMet: boolean | null = null

      condition.source.forEach(source => {
        const conditionResult = ConditionUtils.hasMetCondition(source, getValues())
        if (condition.operator === 'AND') {
          isConditionMet = isConditionMet === null ? conditionResult : isConditionMet && conditionResult
        } else {
          isConditionMet = isConditionMet === null ? conditionResult : isConditionMet || conditionResult
        }
      })

      if (isConditionMet) {
        condition.target.forEach(target => {
          if (target.type === ConditionType.SHOW_OR_HIDE) {
            handleShowHideAction(target)
          } else if (target.type === ConditionType.ENABLE_OR_REQUIRE) {
            handleEnableRequireAction(target)
          }
        })
      } else {
        condition.target.forEach(target => {
          if (target.type === ConditionType.SHOW_OR_HIDE) {
            addRemovedFieldByTarget(target)
          } else if (target.type === ConditionType.ENABLE_OR_REQUIRE) {
            resetTargetToDefaultSettings(target)
          }
        })
      }
    })
  }

  const handleShowHideAction = (target: ConditionTarget): void => {
    if (target.targetAction === DoOptionsShowHide.HIDE) {
      const updatedPages = {}
      Object.keys(fieldPagesLocalRef).forEach(pageKey => {
        const pageFormFields = fieldPagesLocalRef[pageKey].filter(field => !target.targetFieldIds.includes(field.guid!))
        updatedPages[pageKey] = pageFormFields
      })

      onUpdateFormStructure(updatedPages)
    } else if (target.targetAction === DoOptionsShowHide.SHOW) {
      addRemovedFieldByTarget(target)
    }
  }

  const addRemovedFieldByTarget = (target: ConditionTarget): void => {
    if (target.targetAction !== DoOptionsShowHide.SHOW && target.targetAction !== DoOptionsShowHide.HIDE) {
      return
    }

    const updatedPages = {}

    Object.keys(fieldPagesSOT).forEach(pageKey => {
      const removedFields = fieldPagesSOT[pageKey].filter(field => target.targetFieldIds.includes(field.guid!))
      const pageFields = [...fieldPagesLocalRef[pageKey]]
      removedFields.forEach(field => {
        if (!pageFields.find(f => f.guid === field.guid)) {
          pageFields.push(field)
        }
      })
      updatedPages[pageKey] = pageFields
    })
    onUpdateFormStructure(updatedPages)
  }

  const handleEnableRequireAction = (target: ConditionTarget): void => {
    if (target.type !== ConditionType.ENABLE_OR_REQUIRE) {
      return
    }

    const actionHandler = ({ isRequired, isReadonly }: { isRequired: boolean | null; isReadonly: boolean | null }) => {
      const updatedPages = {}
      Object.keys(fieldPagesLocalRef).forEach(pageKey => {
        const pageFields = fieldPagesLocalRef[pageKey]
        pageFields.forEach(field => {
          if (target.targetFieldIds.includes(field.guid!)) {
            if (isRequired !== null) {
              return ((field as BasicField).isRequired = isRequired)
            } else if (isReadonly !== null) {
              return ((field as BasicField).isReadonly = isReadOnly)
            }
          }
        })
        updatedPages[pageKey] = pageFields
      })

      onUpdateFormStructure(updatedPages)
    }

    if (target.targetAction === DoOptionsEnableRequire.DISABLE) {
      actionHandler({ isRequired: null, isReadonly: true })
    } else if (target.targetAction === DoOptionsEnableRequire.ENABLE) {
      actionHandler({ isRequired: null, isReadonly: false })
    } else if (target.targetAction === DoOptionsEnableRequire.REQUIRE) {
      actionHandler({ isRequired: true, isReadonly: null })
    } else if (target.targetAction === DoOptionsEnableRequire.DONT_REQUIRE) {
      actionHandler({ isRequired: false, isReadonly: null })
    }
  }

  const resetTargetToDefaultSettings = (target: ConditionTarget): void => {
    if (target.type !== ConditionType.ENABLE_OR_REQUIRE) {
      return
    }

    const updatedPages = {}
    Object.keys(fieldPagesLocalRef).forEach(pageKey => {
      const pageFields = fieldPagesLocalRef[pageKey]
      pageFields.forEach(field => {
        if (target.targetFieldIds.includes(field.guid!)) {
          const matchingField = fieldPagesSOT[pageKey].find(f => f.guid === field.guid)
          if (matchingField) {
            ;(field as BasicField).isRequired = matchingField.isRequired
            ;(field as BasicField).isReadonly = matchingField.isReadonly
          }
        }
      })
      updatedPages[pageKey] = pageFields
    })

    onUpdateFormStructure(updatedPages)
  }

  const evaluatePageConditions = (newPage: string): { nextPage: string; latestPages?: FormFieldPages } => {
    const pageConditions = conditions.filter(condition => condition.type === ConditionType.SKIP_PAGE)
    const currentPage = page
    let nextPage = newPage
    let latestPages: FormFieldPages = fieldPagesLocalRef
    let hiddenPages = [...hiddenPageIds]
    const allFormFields = Object.values(fieldPages).flat()

    pageConditions.forEach(condition => {
      let isConditionMet: boolean | null = null

      condition.source.forEach(source => {
        if (currentPage && !allFormFields.find((field: FormField) => field.guid === source.formFieldId)) {
          return
        }

        const conditionResult = ConditionUtils.hasMetCondition(source, getValues())

        if (condition.operator === 'AND') {
          isConditionMet = isConditionMet === null ? conditionResult : isConditionMet && conditionResult
        } else {
          isConditionMet = isConditionMet === null ? conditionResult : isConditionMet || conditionResult
        }
      })

      const latestPageKeys = Object.keys(latestPages)
      const latestPageKeysSet = new Set(latestPageKeys)

      if (isConditionMet) {
        condition.target.forEach(target => {
          if (target.type !== ConditionType.SKIP_PAGE) {
            return
          }
          if (target.targetAction === DoOptionsSkipPage.SKIP_TO_PAGE) {
            nextPage = target.targetPageId
          } else if (target.targetAction === DoOptionsSkipPage.HIDE_PAGE) {
            const pageKeys = sortedPages.map(p => p.guid).filter(id => latestPageKeysSet.has(id))

            const isTargetLastPage = pageKeys[pageKeys.length - 1] === target.targetPageId
            const updatedPages = { ...latestPages }

            const uniqueHiddenPageIds = new Set([...hiddenPages, target.targetPageId])
            hiddenPages = Array.from(uniqueHiddenPageIds)

            if (nextPage === target.targetPageId) {
              const currentIndex = pageKeys.findIndex(pageKey => pageKey === nextPage)
              nextPage = pageKeys[currentIndex + 1]
            }

            // When the next page is the last page and it is hidden -> add an empty page to show the captcha &/ submit button
            if (nextPage === target.targetPageId && isTargetLastPage && !pageKeys.find(k => k === TEMP_PAGE_ID)) {
              nextPage = TEMP_PAGE_ID
              updatedPages[TEMP_PAGE_ID] = []
              onUpdateFormStructure(updatedPages)
            }

            latestPages = updatedPages
          }
        })
      } else {
        condition.target.forEach(target => {
          const pageKeys = sortedPages.map(p => p.guid).filter(id => latestPageKeysSet.has(id))

          if (target.type !== ConditionType.SKIP_PAGE) {
            return
          }

          if (target.targetAction === DoOptionsSkipPage.HIDE_PAGE) {
            if (hiddenPages.find(id => id === target.targetPageId)) {
              hiddenPages = hiddenPages.filter((id: string) => id !== target.targetPageId)

              const nextPageGuid = ConditionUtils.getNewPageGuid({
                change: 'increment',
                currPageGuid: currentPage,
                hiddenPageIds: hiddenPages,
                pages: pageKeys,
              })

              nextPage = nextPageGuid
            }
          }
        })
      }
    })

    setHiddenPageIds(hiddenPages)

    return { nextPage, latestPages }
  }

  const onAddEntryToGroupedPage = (
    activePage: Page,
    latestUpdatedFields: FormFieldPages | null,
    listIndex?: number,
  ) => {
    const latestFieldPages = latestUpdatedFields ?? fieldPages
    const latestFieldPagesSOT = latestUpdatedFields ?? fieldPagesSOT
    const baseFields = latestFieldPagesSOT[activePage.guid]?.filter(f => f.listIndex === 0) ?? []
    const currentLength =
      listIndex !== undefined ? listIndex : GroupedPageUtils.getListIndexCount(fieldPages[activePage.guid] ?? [])
    const newFields: FormField[] = []
    const baseFieldIdMapping = {}
    const newFieldIdMapping = {}

    const updateIndexedJsonpath = (jsonPath: string) => {
      return jsonPath.replace(`${activePage.parentJsonpath}[0]`, `${activePage.parentJsonpath}[${currentLength}]`)
    }

    baseFields.forEach((baseField: FormField) => {
      const newId = uuid()

      const newField = {
        ...cloneDeep(baseField),
        guid: newId,
        key: newId,
        layout: { ...baseField.layout, i: newId },
        listIndex: currentLength,
        baseFieldId: baseField.guid,
      }

      if ((newField as BasicField).jsonPath) {
        ;(newField as BasicField).jsonPath = updateIndexedJsonpath((newField as BasicField).jsonPath)

        if (newField.type === FormFieldTypes.Address) {
          newField.cityJsonPath = updateIndexedJsonpath(newField.cityJsonPath)
          newField.countryJsonPath = updateIndexedJsonpath(newField.countryJsonPath)
          newField.postalCodeJsonPath = updateIndexedJsonpath(newField.postalCodeJsonPath)
          newField.streetAddressLine2JsonPath = updateIndexedJsonpath(newField.streetAddressLine2JsonPath)
          newField.streetAddressJsonPath = updateIndexedJsonpath(newField.streetAddressJsonPath)
          newField.stateProvinceJsonPath = updateIndexedJsonpath(newField.stateProvinceJsonPath)
        }
      }

      baseFieldIdMapping[newId] = baseField.guid
      newFieldIdMapping[baseField.guid!] = newId
      newFields.push(newField)
    })

    newFields
      .filter(f => f.type === FormFieldTypes.APILookup)
      .forEach((f: FormField) => {
        assert(f.type === FormFieldTypes.APILookup)

        f.queryParams?.forEach(param => {
          param.targetFormFieldId = newFieldIdMapping[param.targetFormFieldId]
        })

        f.lookupMapping?.forEach(mapping => {
          mapping.targetFormFieldId = newFieldIdMapping[mapping.targetFormFieldId]
        })
      })

    fieldPagesLocalRef = {
      ...latestFieldPages,
      [activePage.guid]: [...latestFieldPages[activePage.guid], ...newFields],
    }
    setFieldPagesSOT({
      ...latestFieldPagesSOT,
      [activePage.guid]: [...latestFieldPagesSOT[activePage.guid], ...newFields],
    })
    setFieldPages(fieldPagesLocalRef)
    setFormFields([...fieldPages[activePage.guid], ...newFields])

    /**
     * Assign new conditions for the new fields based on the base fields' conditions.
     */
    const baseFieldIds: string[] = Object.keys(newFieldIdMapping)
    const newConditions: Condition[] = []

    conditions.forEach(condition => {
      newFields.forEach(newField => {
        const newCondition: Condition = {
          ...condition,
          guid: uuid(),
          groupedPageId: activePage.guid,
          listIndex: currentLength,
        }
        let hasNewChange = false

        const isBaseFieldInSource = !!condition.source.find(s => s.formFieldId === baseFieldIdMapping[newField.guid!])

        const isAnyOfTheBaseFieldsAreInSource = condition.source.some(s =>
          baseFieldIds.find(baseFieldId => baseFieldId === s.formFieldId),
        )

        const isBaseFieldInTargetCondition = !!condition.target.find(t =>
          (t as ConditionTargetEnableRequire | ConditionTargetShowHide)?.targetFieldIds?.includes(
            baseFieldIdMapping[newField.guid!],
          ),
        )

        if (isBaseFieldInSource) {
          newCondition.source = condition.source.map(s => {
            if (baseFieldIds.find(baseFieldId => baseFieldId === s.formFieldId)) {
              return { ...s, formFieldId: newFieldIdMapping[s.formFieldId] }
            }
            return s
          })

          newCondition.target = condition.target.map(t => {
            const targetFieldIds = (t as ConditionTargetEnableRequire | ConditionTargetShowHide).targetFieldIds.map(
              (tfId: string) => {
                if (baseFieldIds.includes(tfId)) {
                  return newFieldIdMapping[tfId]
                }

                return tfId
              },
            )

            return { ...t, targetFieldIds }
          })

          hasNewChange = true
        } else if (isBaseFieldInTargetCondition && !isAnyOfTheBaseFieldsAreInSource) {
          condition.target.forEach(t =>
            (t as ConditionTargetEnableRequire | ConditionTargetShowHide).targetFieldIds.push(newField.guid!),
          )
        }

        if (hasNewChange) {
          newConditions.push(newCondition)
        }
      })
    })

    setConditions(currentConditions => [...currentConditions, ...newConditions])
    setNewFields(newFields)

    return fieldPagesLocalRef
  }

  const onRemoveEntryFromGroupedPage = (activePage: Page, index: number) => {
    if (!fieldPages[activePage.guid]) {
      return
    }

    const filteredFields = fieldPages[activePage.guid].filter(f => f.listIndex !== index) ?? []
    const filteredFieldsSOT = fieldPagesSOT[activePage.guid].filter(f => f.listIndex !== index) ?? []

    const updatedFields = filteredFields.map(f => {
      if (f.listIndex > index) {
        // Decrement listIndex for fields after the removed entry.
        return { ...f, listIndex: f.listIndex - 1 }
      }
      return f
    })

    const updatedFieldsSOT = filteredFieldsSOT.map(f => {
      if (f.listIndex > index) {
        // Decrement listIndex for fields after the removed entry.
        return { ...f, listIndex: f.listIndex - 1 }
      }
      return f
    })

    setFieldPagesSOT({
      ...fieldPagesSOT,
      [activePage.guid]: [...updatedFieldsSOT],
    })
    setFieldPages({ ...fieldPages, [activePage.guid]: updatedFields })
    setFormFields(updatedFields)
  }

  useEffect(() => {
    if (!isLoading && fieldPages) {
      const allFormFields = Object.values(fieldPages).flat()
      const referencedFormulaFields = Object.values(fieldPages)
        .flat()
        .reduce((acc: string[], field) => {
          if ('formula' in field && !isNil(field.formula)) {
            return [...acc, ...FormBuilderUtils.substituteFormulaFields(field.formula, allFormFields, {}).fieldGuids]
          }
          return acc
        }, [])
      setFormulaFieldsGuids(referencedFormulaFields)
    }
  }, [isLoading])

  useEffect(() => {
    const referencedFormulaFields = Object.fromEntries(
      formulaFieldsGuids.map((fieldGuid, index) => [fieldGuid, referencedFormulaFieldsValues[index]]),
    )
    const formulaFields = Object.values(fieldPages)
      .flat()
      .filter(field => 'formula' in field && !isNil(field.formula)) as NumericField[]

    formulaFields.forEach(field => {
      const { formula, fieldGuids } = FormBuilderUtils.substituteFormulaFields(
        field.formula as string,
        formFields,
        referencedFormulaFields,
      )

      if (fieldGuids.every(guid => !isNil(referencedFormulaFields[guid]))) {
        const parser = new Parser()
        try {
          const parsedValue = parser.parse(formula).evaluate(referencedFormulaFields)
          setValue(field.guid as never, parsedValue as never)
        } catch (e) {
          setValue(field.guid as never, null as never)
        }
      } else {
        setValue(field.guid as never, null as never)
      }
    })
  }, [referencedFormulaFieldsValues])

  useEffect(() => {
    const fieldIds = Object.keys(fieldConditionMap)
    if (!fieldIds.length || isEqual(conditionSources, previousValues)) {
      return
    }
    fieldIds.forEach((formFieldId: string, ndx: number) => {
      if (previousValues === undefined || previousValues[ndx] !== conditionSources[ndx]) {
        triggerConditionChecking(formFieldId)
      }
    })
    setPreviousValues(conditionSources as any)
  }, [conditionSources])

  useEffect(() => {
    if (!isEqual(newFields, previousFields) || !isEqual(conditionMapKeys, previousConditionKeys)) {
      newFields.forEach(field => triggerConditionChecking(field.guid!))
      setPreviousConditionKeys(conditionMapKeys)
      setPreviousFields(newFields)
    }
  }, [newFields, conditionMapKeys])

  useEffect(() => {
    if (conditions && fieldPages) {
      const allFormFields = Object.values(fieldPages).flat()
      const updatedConditionMap = ConditionUtils.generateFieldConditionMap(conditions, allFormFields)
      setFieldConditionMap(updatedConditionMap)
    }
  }, [conditions])

  return (
    <FormStateContext.Provider
      value={{
        fieldPagesSOT,
        setFieldPagesSOT,
        fieldPages,
        setFieldPages,
        formFields,
        setFormFields,
        latestFormData,
        page,
        setPage,
        fieldConditionMap,
        setFieldConditionMap,
        conditions,
        setConditions,
        evaluatePageConditions,
        hiddenPageIds,
        handleSubmit,
        control,
        formState,
        unregister,
        getValues,
        trigger,
        clearErrors,
        setValue,
        watch,
        pages,
        setPages,
        onAddEntryToGroupedPage,
        onRemoveEntryFromGroupedPage,
        isPrintMode,
        setIsPrintMode,
        isReadOnly,
        setIsReadOnly,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </FormStateContext.Provider>
  )
}

export const useFormState = () => {
  const context = useContext(FormStateContext)
  if (context === undefined) {
    throw new Error('useFormState must be used within a FormStateProvider')
  }
  return context
}
