import {
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Loader } from '@unmand-systems/components'
import jsonpath from 'jsonpath'
import { cloneDeep, isEmpty, isNil, merge } from 'lodash'
import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { HiExclamationCircle } from 'react-icons/hi'
import tinycolor from 'tinycolor2'
import { EMPTY_FN, GRID_ROW_HEIGHT, MIN_FORM_WIDTH, TASK_DATA_GRID_COLUMNS, cols } from '../constants'
import { GoogleMapsProvider } from '../context'
import { FormAccessibilityProvider } from '../context/FormAccessibilityContext'
import { useFormState } from '../context/FormStateContext'
import {
  APIFormField,
  BasicField,
  ErrorResponse,
  FlatData,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  FormResponse,
  HeaderField,
  Page,
  ProgressComponentType,
  SliderFieldType,
} from '../interfaces'
import { UnmandFormComponent } from '../interfaces/unmand-form-component'
import { FormsApi } from '../services'
import { ConditionUtils, FormRendererUtils, GeneralUtils, GroupedPageUtils, StyleUtils, collectStyles } from '../utils'
import { FormBuilderUtils } from '../utils/form-builder.utils'
import { SubmittedFormDetails } from './SubmittedFormDetails'
import { FormStepper } from './core/FormStepper'
import { NAVBAR_HEIGHT, TopNav } from './core/TopNav'
import { FormFieldInstance } from './field-inputs'
import {
  DisabledPage,
  DisabledPageProps,
  ErrorPage,
  NotFound,
  PasswordPage,
  SuccessPage,
  UnauthorizedPage,
} from './screen-states'
import { useConfirmationDialog } from '../hooks'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const DEFAULT_SUCCESS_TITLE = 'Successfully submitted!'
const DEFAULT_SUCCESS_BODY = 'You have successfully submitted this form.'

const ResponsiveGridLayout = WidthProvider(Responsive)

const SUBMITTED_DATA_BLOCK_ID = 'unmand-submitted-data'

interface SuccessPage {
  messageTitle: string
  messageDescription: string
}

interface FormProps {
  unmandForm: UnmandFormComponent
  scriptReference: HTMLOrSVGScriptElement | null
}

export const Form: React.FC<FormProps> = ({ unmandForm, scriptReference }) => {
  const {
    formFields,
    fieldPages,
    fieldPagesSOT,
    page,
    latestFormData,
    setConditions,
    setFieldConditionMap,
    setFieldPages,
    setPage,
    setFormFields,
    setFieldPagesSOT,
    evaluatePageConditions,
    hiddenPageIds,
    pages,
    setPages,
    onAddEntryToGroupedPage,
    onRemoveEntryFromGroupedPage,
    isPrintMode,
    setIsPrintMode,
    isReadOnly,
    setIsReadOnly,
    isLoading,
    setIsLoading,
    /**
     * React hook form methods
     */
    handleSubmit,
    control,
    formState: { errors, isDirty },
    unregister,
    getValues,
    clearErrors,
    trigger,
    setValue,
  } = useFormState()

  const [hasPassword, setHasPassword] = useState<boolean>()
  const [disabledPage, setDisabledPage] = useState<DisabledPageProps | null>(null)
  const [successPage, setSuccessPage] = useState<SuccessPage | null>(null)
  const [formId, setFormId] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [password, setPassword] = useState<string>()

  const [isFromScript, setIsFromScript] = useState<boolean>(false)
  const [formResponse, setFormResponse] = useState<FormResponse | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isInvalidPassword, setIsInvalidPassword] = useState<boolean>(false)
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [isNotFound, setIsNotFound] = useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()
  const [isCaptchaEnabled, setIsCaptchaEnabled] = useState<boolean>(false)
  const [hasValidRecaptcha, setHasValidRecaptcha] = useState<boolean>(false)
  const [pdfUrl, setPdfUrl] = useState<string>()
  const [prefillId, setPrefillId] = useState<string>()
  const [prefillData, setPrefillData] = useState<any>()
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [hasInit, setHasInit] = useState<boolean>(false)
  const [defaultValuesFromUrl, setDefaultValuesFromUrl] = useState<{ [jsonPathKey: string]: any }>({})

  const manualSubmitBtnRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const { showConfirmDialog: openConfirmationDialog, RenderDialog: ConfirmationDialog } = useConfirmationDialog({
    title: 'Are you sure?',
    message: 'Are you sure you want to remove this? This cannot be undone.',
    primaryBtnLabel: 'Remove',
    secondaryBtnLabel: 'Cancel',
  })

  const isMobile = GeneralUtils.isMobileBrowser()
  const pageKeys = GeneralUtils.sortPages(formResponse?.pages ?? []).map(page => page.guid)
  const totalPages = pageKeys.length
  const fieldLayouts =
    (page &&
      fieldPages[page]?.map(field => {
        let layout = field.layout!

        if (isMobile) {
          layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
        }

        return layout
      })) ??
    []
  const currPageGuid = pageKeys.find(key => key === page)
  const isLastPage = currPageGuid === pageKeys.filter(key => !hiddenPageIds.includes(key)).at(-1) || !currPageGuid

  const progress = ((pageKeys.findIndex(guid => guid === currPageGuid) + 1) / (totalPages + 1)) * 100
  const { nextButtonText, previousButtonText, nextButtonAlignment, previousButtonAlignment } =
    formResponse?.pages.find(p => p.guid === page) || {}

  const activePage = pages ? pages.find(p => p.guid === page) || pages[0] : null

  const hasRunOnce = useRef(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const { style } = formResponse ?? {}
  const allFormFields: FormField[] = Object.values(fieldPages).flat()
  const [isStepperInitialized, setIsStepperInitialized] = useState<boolean>(false)

  unmandForm.getData = () => {
    return latestFormData
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = () => {
    if (hasRunOnce.current) {
      return
    }
    let formId = ''
    let authToken = ''
    let isPreview = false
    let prefillId = ''

    if (scriptReference && !GeneralUtils.isLoadedFromUrl(scriptReference?.getAttribute('src'))) {
      formId = scriptReference.getAttribute('form-id')!
      authToken = scriptReference.getAttribute('auth-token')!
      isPreview = scriptReference.getAttribute('is-preview') === 'true'
      prefillId = scriptReference.getAttribute('prefill-id')!
      setIsFromScript(true)
    } else {
      const urlParams = new URLSearchParams(window.location.search)
      formId = window.location.pathname.replace('/', '')
      authToken = urlParams.get('token')!
      isPreview = urlParams.get('is-preview') === 'true'
      prefillId = urlParams.get('prefill')!

      const defaultValues = {}
      urlParams.forEach((value, key) => {
        if (!['token', 'prefill', 'is-preview'].includes(key)) {
          defaultValues[key] = value
        }
      })

      setDefaultValuesFromUrl(defaultValues)
    }

    setFormId(formId as string)
    setAuthToken(authToken as string)
    setIsPreview(isPreview)
    setPrefillId(prefillId)
    fetchFormDetails(formId, authToken, isPreview)

    hasRunOnce.current = true
  }

  const fetchPrefilledData = async (pageList: Page[], prefillId: string, formFields: FormField[]) => {
    try {
      const data = await FormsApi.getPrefilledData(prefillId)
      setPrefillData(data)

      let latestFields = handleGroupedPagePrefills(pageList, data)

      if (!latestFields.length) {
        latestFields = formFields
      }

      const updatedData = FormRendererUtils.updateFormDataByJson(data, latestFields)

      Object.keys(updatedData).forEach(key => setValue(key, updatedData[key]))

      setDefaultSliderValue(updatedData, formFields)
    } catch (e) {
      console.error(e)
    }
  }

  const handleGroupedPagePrefills = (pageList: Page[], prefillData: any) => {
    let latestFieldPages: FormFieldPages | null = null
    let latestFormFields: FormField[] = []

    pageList
      .filter(p => p.isGrouped && p.parentJsonpath)
      .forEach(page => {
        const val = jsonpath.value(prefillData, page.parentJsonpath!)

        if (val && Array.isArray(val) && val.length > 1) {
          for (let i = 1; i < val.length; i++) {
            latestFieldPages = onAddEntryToGroupedPage(page, latestFieldPages, i) ?? {}
            latestFormFields = Object.values(latestFieldPages).flat() as FormField[]
          }
        }
      })

    return latestFormFields
  }

  const fetchFormDetails = async (formId: string, authToken: string, isPreview: boolean) => {
    setIsLoading(true)
    setHasPassword(false)
    let response: FormResponse

    try {
      if (authToken && !isPreview) {
        response = await FormsApi.getFormPrivate(formId, authToken, password)
      } else if (authToken && isPreview) {
        response = await FormsApi.getFormPreview(formId, authToken, password)
      } else {
        response = await FormsApi.getFormPublic(formId, password)
      }

      const formResponse = GroupedPageUtils.parseFormResponse(response)
      setFormResponse(formResponse)
      document.title = formResponse.form.name

      if (formResponse.form.faviconUrl) {
        let link = document.querySelector("link[rel~='icon']") as any

        if (!link) {
          link = document.createElement('link')
          link.rel = 'icon'
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = formResponse.form.faviconUrl
      }

      setIsCaptchaEnabled(formResponse.form.captchaEnabled)
      setSuccessPage({
        messageTitle: formResponse.form.completedMessageTitle ?? DEFAULT_SUCCESS_TITLE,
        messageDescription: formResponse.form.completedMessageBody ?? DEFAULT_SUCCESS_BODY,
      })
      const fieldPagesResponse = FormBuilderUtils.convertFormPages(formResponse.layout)

      setFieldPages(fieldPagesResponse)
      setFieldPagesSOT(cloneDeep(fieldPagesResponse))

      setPage(formResponse.pages[0].guid)
      setPages(formResponse.pages)
      setFormFields(fieldPagesResponse[formResponse.pages[0].guid])
      setIsReadOnly(formResponse.form.readOnly)

      const formFields: APIFormField[] = Object.values(formResponse.layout).flat()
      const conditions = cloneDeep(formResponse.conditions)

      setFieldConditionMap(ConditionUtils.generateFieldConditionMap(conditions ?? [], formFields as any as FormField[]))
      setConditions(conditions)

      setHasInit(true)
    } catch (e: unknown) {
      const error = e as ErrorResponse

      if (error.status === 418) {
        setHasPassword(true)
        setIsInvalidPassword(!!password)
      } else if (error.status === 410) {
        setDisabledPage({
          messageTitle: error.messageTitle || 'This form is no longer accepting submissions',
          messageDescription: error.messageDescription || 'Please contact the form owner for details.',
        })
      } else if (error.status === 401) {
        setIsUnauthorized(true)
      } else if (error.status === 404) {
        setIsNotFound(true)
      } else {
        setErrorMsg(error.message!)
      }
    }

    setIsLoading(false)
  }

  const pageFields: FormField[] = page ? FormBuilderUtils.sortedByPosition(fieldPages[page]) : []

  const keys = pageFields
    .map((field: { type: FormFieldTypes; key: string }) => {
      if (field.type === FormFieldTypes.Address) {
        return FormBuilderUtils.getRequiredAddressKeys(field.key)
      } else {
        return field.key
      }
    })
    .flat() as string[]

  const onPageChangeClick =
    (change: 'increment' | 'decrement', pageGuid?: string) => async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      clearErrors()
      onPageChange(change, pageGuid)
    }

  const onPageChange = async (change: 'increment' | 'decrement', pageGuid?: string) => {
    containerRef.current?.scrollTo({ top: 0 })

    let newPageGuid =
      pageGuid ??
      ConditionUtils.getNewPageGuid({
        change,
        currPageGuid,
        hiddenPageIds,
        pages: pageKeys,
      })

    let latestPagesList
    if (change === 'increment') {
      const { latestPages, nextPage } = evaluatePageConditions(newPageGuid)
      newPageGuid = nextPage
      latestPagesList = latestPages
    }

    if (change === 'decrement' || (await trigger(keys))) {
      if (change === 'increment' && newPageGuid === undefined && isLastPage) {
        manualSubmitBtnRef.current?.click()
      } else if (newPageGuid) {
        setPage(newPageGuid)

        if (change === 'increment') {
          setFormFields(latestPagesList?.[newPageGuid] ?? [])
        } else {
          setFormFields(fieldPages[newPageGuid] ?? [])
        }
      }
    }
  }

  const onNavigateByFieldGuid = (fieldGuid: string) => {
    const toPageGuid = FormRendererUtils.findPageGuidByFieldGuid(fieldGuid, fieldPagesSOT)
    if (toPageGuid !== currPageGuid) {
      onPageChange('increment', toPageGuid)
    } else {
      FormRendererUtils.scrollFieldIntoView(fieldGuid)
    }
  }

  const onSubmitForm = async (data: FlatData) => {
    if (isPreview) {
      return
    }

    setErrorMsg('')
    setIsSubmitting(true)

    try {
      /**
       * Merge default payload with form data
       */
      const defaultPayload = formResponse?.form?.defaultPayload
        ? JSON.parse(formResponse.form.defaultPayload || '{}')
        : {}
      let formData = merge(data, defaultPayload)

      /**
       * Merge default values from URL (that don't have a dedicated field) with form data
       */
      const defaultValuesWithNoFields = {}
      Object.keys(defaultValuesFromUrl)
        .filter(key => jsonpath.value(formData, key) === undefined)
        .forEach(key => {
          FormRendererUtils.createNestedObjectByJsonPath(defaultValuesWithNoFields, key, defaultValuesFromUrl[key])
        })

      formData = merge(formData, defaultValuesWithNoFields)

      const htmlString = `
      <html>
        <head>
          <link rel="stylesheet" href="https://unmand-forms.com/form.css" />
          <style>
          ${collectStyles(SUBMITTED_DATA_BLOCK_ID)}

          @media print {
            body {
              margin: 0.6cm;
            }
            .react-grid-item {
              margin: 0 5px;
            }
          }
          </style>
        </head>
        <body>
          ${document.getElementById(SUBMITTED_DATA_BLOCK_ID)?.innerHTML}
        </body>
      </html>
      `

      let htmlFile: File | undefined

      if (formResponse?.form.attachHtmlFile) {
        const blob = new Blob([htmlString], { type: 'text/html' })
        htmlFile = new File([blob], 'submitted_data.html', { type: 'text/html' })
      }

      const fieldStoreValues = {}

      if (formResponse?.form?.swarmDataStoreMapping?.length) {
        const dataStoreMapping = formResponse.form.swarmDataStoreMapping

        for (const mapping of dataStoreMapping) {
          const field = allFormFields.find(f => f.guid === mapping.field_guid)
          if (field) {
            const value = jsonpath.value(formData, (field as BasicField).jsonPath!)
            if (value) {
              fieldStoreValues[mapping.field_guid] = value
            }
          }
        }
      }

      if (authToken) {
        const url = await FormsApi.submitFormPrivate({
          formId: formId!,
          formData,
          authToken,
          password,
          prefill: prefillData ? prefillId : undefined,
          htmlFile,
          fieldStoreValues,
        })
        setPdfUrl(url)
      } else {
        const url = await FormsApi.submitFormPublic({
          formToken: formId!,
          formData,
          password,
          prefill: prefillData ? prefillId : undefined,
          htmlFile,
          fieldStoreValues,
        })
        setPdfUrl(url)
      }

      setIsSuccessful(true)
    } catch (e) {
      const error = e as ErrorResponse
      console.error(error)
      setErrorMsg(error.message!)
    }
    setIsSubmitting(false)
  }

  const initializeDefaultValues = async () => {
    // if any form fields have defaultValue, set them
    Object.values(fieldPages)
      .flat()
      .forEach(field => {
        if ('defaultValue' in field && !isNil(field.defaultValue) && field.defaultValue !== '') {
          setValue(field.key, field.defaultValue)
        }
      })

    if (!isEmpty(defaultValuesFromUrl)) {
      const allFormFields = Object.values(fieldPages).flat()
      const objectFromJsonpath = {}
      Object.keys(defaultValuesFromUrl).forEach(key => {
        FormRendererUtils.createNestedObjectByJsonPath(objectFromJsonpath, key, defaultValuesFromUrl[key])
      })

      const updatedData = FormRendererUtils.updateFormDataByJson(objectFromJsonpath, allFormFields)

      Object.keys(updatedData).forEach(key => {
        if (updatedData[key] !== undefined) {
          setValue(key, updatedData[key])
        }
      })
    }

    setDefaultSliderValue(getValues(), formFields)

    if (prefillId) {
      await fetchPrefilledData(
        pages,
        prefillId,
        Object.values(fieldPages).flatMap(array => array),
      )
    }

    if (currPageGuid) evaluatePageConditions(currPageGuid)

    setTimeout(() => {
      setIsStepperInitialized(true)
    }, 1500) // This is added to prevent the stepper from flickering on page load
  }

  const setDefaultSliderValue = (formFieldValues: { [key: string]: any }, formFields: FormField[]) => {
    formFields
      .filter(f => f.type === FormFieldTypes.Slider)
      .forEach(f => {
        const sliderField = f as SliderFieldType
        if (isNaN(formFieldValues[sliderField.key])) {
          setValue(sliderField.key, sliderField.restrictedValues?.[0] ?? sliderField.minimumNumber ?? 0)
        }
      })
  }

  const onDisplayErrors = () => {
    toast.closeAll()
    const errorKeys = Object.keys(errors ?? {})

    if (errorKeys.length > 6) {
      return
    }

    errorKeys.forEach(key => {
      toast({
        position: 'top',
        isClosable: true,
        duration: 10000,
        status: 'error',
        variant: 'subtle',
        render: ({ onClose }) => (
          <Box
            color="white"
            bg="red.500"
            p={3}
            borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
            display="flex"
            alignItems="center"
          >
            <Box flex="1">
              <Text as="span" fontWeight="bold">
                {(allFormFields?.find((f: FormField) => f.guid === key) as BasicField)?.name ?? 'Field'}
                {' - '}
              </Text>
              <Text as="span">Invalid Value</Text>
            </Box>
            <Button
              colorScheme="gray"
              bg="white"
              color="black"
              size="xs"
              ml={4}
              borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
              onClick={() => FormRendererUtils.scrollFieldIntoView(key)}
            >
              See Error
            </Button>
            <CloseButton onClick={onClose} color="white" ml="1" />
          </Box>
        ),
      })
    })
  }

  useEffect(() => {
    initializeDefaultValues()
  }, [hasInit])

  useEffect(() => {
    if (activePage?.guid) {
      setSelectedTabIndex(0)
    }
  }, [activePage?.guid])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
    }

    if (isDirty && !isSuccessful) {
      window.addEventListener('beforeunload', handler)
      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
    return EMPTY_FN
  }, [isDirty, isSuccessful])

  if (isLoading) {
    return (
      <Center w="100vw" h="100vh" bg="gray.50">
        <Loader size="lg"></Loader>
      </Center>
    )
  }

  if (errorMsg) {
    return <ErrorPage errorMsg={errorMsg} />
  }

  if (isNotFound) {
    return <NotFound />
  }

  if (disabledPage) {
    return <DisabledPage {...disabledPage} />
  }

  if (isUnauthorized) {
    return <UnauthorizedPage />
  }

  if (hasPassword) {
    return (
      <PasswordPage
        isInvalidPassword={isInvalidPassword}
        setIsInvalidPassword={setIsInvalidPassword}
        password={password}
        setPassword={setPassword}
        onSubmit={() => {
          fetchFormDetails(formId!, authToken!, isPreview)
        }}
      />
    )
  }

  const { colors, inputs, layout, buttons } = StyleUtils.getTheme(style ?? {})
  const progressComponentType = formResponse?.form?.progressComponentType
  const activeGroupedPageIndexes = Array.from(
    { length: GroupedPageUtils.getListIndexCount(formFields) },
    (_, idx) => idx,
  )
  const displayedFields = activePage?.isGrouped ? formFields.filter(f => f.listIndex === selectedTabIndex) : formFields
  const tabIndexesWithError = activePage?.isGrouped
    ? Array.from(new Set(formFields.filter(f => !!errors[f.key]).map(f => f.listIndex)).values())
    : []

  return (
    <Flex
      className="unmand-form-wrapper"
      flexDir="column"
      position="relative"
      minW={{ base: '0px', md: MIN_FORM_WIDTH }}
      w="100%"
      minH="100vh"
      {...(isPrintMode
        ? {
            maxW: '800px',
            margin: '0 auto',
          }
        : {})}
    >
      {!isFromScript && !isPrintMode && (
        <TopNav
          title={formResponse?.form?.name}
          logoUrl={formResponse?.form?.logoUrl}
          progress={isSuccessful ? 100 : progress}
          style={style}
          progressComponentType={progressComponentType ?? ProgressComponentType.STEPPER}
          isReadOnly={isReadOnly}
        />
      )}
      {isSuccessful && successPage ? (
        <SuccessPage
          {...successPage}
          formName={formResponse?.form.name}
          downloadPdfEnabled={formResponse?.form.submissionDownloadEnabled}
          pdfUrl={pdfUrl}
          onPrintPdf={() => {
            setIsPrintMode(true)
            setTimeout(() => {
              print()
            }, 500)
          }}
        />
      ) : !isPrintMode ? (
        <Box
          ref={containerRef}
          textAlign="center"
          fontSize="xl"
          p={{ base: 1, md: '6' }}
          minW={{ base: '0px', md: MIN_FORM_WIDTH }}
          height={`calc(100vh - ${NAVBAR_HEIGHT})`}
          overflowY="auto"
          sx={{
            '& .chakra-form__required-indicator': {
              display: inputs.hideRequiredAsterisk ? 'none' : 'block',
              ml: 0,
            },
            '& .chakra-form__error-message': {
              fontSize: 'xs',
              mt: '6px',
            },
            '& .form__optional-indicator': {
              display: 'inline-block',
              color: 'gray.500',
              fontSize: 'xs',
              fontStyle: 'italic',
              fontWeight: 400,
            },
          }}
        >
          <GoogleMapsProvider pages={fieldPagesSOT}>
            <FormAccessibilityProvider fields={formFields}>
              <Container py={{ base: '0', md: '4' }} px={{ base: '0', md: 'inherit' }}>
                <Card
                  variant="outline"
                  p={{ base: '0', md: '4' }}
                  borderRadius={layout.borderRadius}
                  bg={layout.backgroundColor}
                  color={colors.labelColor}
                >
                  {progressComponentType === ProgressComponentType.STEPPER && isStepperInitialized && (
                    <FormStepper
                      showName={!!formResponse?.form.showStepName}
                      style={style}
                      pages={formResponse?.pages.filter(page => !hiddenPageIds.includes(page.guid))}
                      currPage={formResponse?.pages.find(p => p.guid === page)}
                      onPageChange={onPageChangeClick}
                      isReadOnly={formResponse?.form.readOnly}
                    />
                  )}
                  <form
                    noValidate
                    onSubmit={handleSubmit(async () => {
                      const updatedData = FormRendererUtils.updateDataByJsonpaths(getValues(), fieldPages, {}, EMPTY_FN)
                      if (!isPreview) {
                        onSubmitForm(updatedData)
                      }
                    })}
                  >
                    <VStack alignItems="start">
                      {activePage && activePage.isGrouped && (
                        <Flex
                          w="100%"
                          px="4"
                          pos="sticky"
                          zIndex="1"
                          top={-6}
                          bg={layout.backgroundColor}
                          alignItems="center"
                          gap={4}
                          overflow="hidden"
                          maxW="100%"
                          borderBottom="1px"
                          borderColor={
                            tinycolor(layout.backgroundColor).isLight() ? 'blackAlpha.300' : 'whiteAlpha.500'
                          }
                          justifyContent="space-between"
                        >
                          <Tabs overflow="auto" index={selectedTabIndex} onChange={idx => setSelectedTabIndex(idx)}>
                            <TabList borderColor="transparent">
                              {activeGroupedPageIndexes?.map((_, idx) => {
                                return (
                                  <Tab
                                    minH="50px"
                                    position="relative"
                                    key={`sidebar-btn-${idx}`}
                                    whiteSpace="nowrap"
                                    opacity={0.8}
                                    borderColor="transparent"
                                    _selected={{
                                      opacity: 1,
                                      borderColor: colors.primaryColor,
                                      color: colors.primaryColor,
                                    }}
                                  >
                                    <Flex justifyContent="space-between" w="100%" alignItems="center">
                                      {tabIndexesWithError.includes(idx) && (
                                        <Icon mr={2} as={HiExclamationCircle} color="red.600" />
                                      )}
                                      <Text>
                                        {GroupedPageUtils.getTabLabel(activePage, idx, formFields, getValues)}
                                      </Text>
                                      {activeGroupedPageIndexes.length > 1 &&
                                        selectedTabIndex === idx &&
                                        selectedTabIndex !== 0 && (
                                          <Tooltip label="Remove entry">
                                            <IconButton
                                              colorScheme="red"
                                              size="xs"
                                              variant="ghost"
                                              aria-label="Remove entry"
                                              p={1}
                                              ml={2}
                                              mr={-2}
                                              icon={<FiTrash2 />}
                                              onClick={() => {
                                                openConfirmationDialog(() => {
                                                  onRemoveEntryFromGroupedPage(activePage, idx)
                                                  setSelectedTabIndex(idx !== 0 ? idx - 1 : 0)
                                                })
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                    </Flex>
                                  </Tab>
                                )
                              })}
                            </TabList>
                          </Tabs>
                          <Button
                            size="xs"
                            flex="none"
                            {...StyleUtils.getSubtleButtonStyle(colors.primaryColor)}
                            leftIcon={<FiPlus />}
                            onClick={() => {
                              onAddEntryToGroupedPage(activePage, null)
                              setSelectedTabIndex(activeGroupedPageIndexes.length)
                            }}
                          >
                            Add
                          </Button>
                        </Flex>
                      )}
                      <Box w="100%">
                        <ResponsiveGridLayout
                          className="layout"
                          cols={cols}
                          margin={[16, layout.padding]}
                          rowHeight={GRID_ROW_HEIGHT}
                          isDraggable={false}
                          isResizable={false}
                          layouts={{
                            lg: fieldLayouts,
                            md: fieldLayouts,
                            sm: fieldLayouts,
                            xs: fieldLayouts,
                            xxs: fieldLayouts,
                          }}
                        >
                          {displayedFields
                            .filter(f => !(f as HeaderField).showOnlyOnPrint)
                            .map(f => {
                              const field = f

                              let layout = field.layout
                              if (isMobile) {
                                layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
                              }

                              return (
                                <Box
                                  id={`formfield-${field.guid}`}
                                  key={field.key}
                                  data-grid={layout}
                                  role="group"
                                  overflow={field.type === FormFieldTypes.MultiSelect ? 'auto' : 'initial'}
                                >
                                  <Flex
                                    alignItems={field.type === FormFieldTypes.Markdown ? 'flex-start' : 'center'}
                                    gap={2}
                                    height="100%"
                                  >
                                    <Box display="contents">
                                      <FormFieldInstance
                                        variant={inputs.variant}
                                        isDisabled={false}
                                        field={field}
                                        control={control}
                                        errors={errors}
                                        isLabelHidden={false}
                                        defaultData={{}}
                                        unregister={unregister}
                                        setValue={setValue}
                                        style={style ?? {}}
                                        onNavigateByFieldGuid={onNavigateByFieldGuid}
                                      />
                                    </Box>
                                  </Flex>
                                </Box>
                              )
                            })}
                        </ResponsiveGridLayout>
                      </Box>
                    </VStack>
                    {isCaptchaEnabled && (
                      <Flex w="100%" justifyContent="center" pt="4" display={isLastPage ? 'flex' : 'none'}>
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_KEY}
                          onChange={value => {
                            setHasValidRecaptcha(!!value)
                          }}
                        />
                      </Flex>
                    )}

                    <HStack my="4" justifyContent="center" gap={0}>
                      {page !== pageKeys[0] && (
                        <Center
                          py={2}
                          px={4}
                          w="100%"
                          justifyContent={previousButtonAlignment ?? undefined}
                          sx={
                            previousButtonAlignment === 'stretch'
                              ? {
                                  '& > *': {
                                    w: '100%',
                                  },
                                }
                              : {}
                          }
                        >
                          <Button
                            px={8}
                            type="button"
                            isDisabled={page === '1'}
                            onClick={onPageChangeClick('decrement')}
                            size={buttons.size}
                            borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
                            {...StyleUtils.getSubtleButtonStyle(colors.primaryColor)}
                          >
                            {previousButtonText ? previousButtonText : 'Back'}
                          </Button>
                        </Center>
                      )}

                      {!(isLastPage && isReadOnly) && (
                        <Center
                          py={2}
                          px={4}
                          w="100%"
                          justifyContent={nextButtonAlignment ?? undefined}
                          sx={
                            nextButtonAlignment === 'stretch'
                              ? {
                                  '& > *': {
                                    w: '100%',
                                  },
                                }
                              : {}
                          }
                        >
                          <Button
                            px={8}
                            type={isLastPage ? 'submit' : 'button'}
                            onClick={async e => {
                              if (isLastPage) {
                                EMPTY_FN()
                              } else {
                                e.preventDefault()
                                await onPageChange('increment')
                              }

                              onDisplayErrors()
                            }}
                            isLoading={isSubmitting}
                            isDisabled={isCaptchaEnabled && isLastPage && !hasValidRecaptcha}
                            size={buttons.size}
                            border={buttons.border ? `${StyleUtils.toPixels(buttons.borderWidth)} solid` : undefined}
                            borderColor={buttons.border ? buttons.borderColor : undefined}
                            borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
                            {...StyleUtils.getSolidButtonStyle(colors.primaryColor, colors.buttonTextColor)}
                          >
                            {nextButtonText ? nextButtonText : isLastPage ? 'Submit' : 'Continue'}
                          </Button>
                        </Center>
                      )}
                      <Box className="unmand-form-input-errors" display="none">
                        {JSON.stringify(errors)}
                      </Box>
                      <Button id="manualSubmitBtn" display="none" type="submit" ref={manualSubmitBtnRef}></Button>
                    </HStack>
                  </form>
                </Card>
              </Container>
            </FormAccessibilityProvider>
          </GoogleMapsProvider>
          <ConfirmationDialog />
        </Box>
      ) : (
        <></>
      )}
      {isPrintMode && <SubmittedFormDetails formResponse={formResponse!} />}

      {isLastPage && (
        <Box position="absolute" left="-9999px" top="-99999px" visibility="hidden" w="100%">
          <Box id={SUBMITTED_DATA_BLOCK_ID} w="100%">
            <Flex w="100%" justifyContent="center">
              <Flex
                className="unmand-form-wrapper"
                flexDir="column"
                position="relative"
                minW={{ base: '0px', md: MIN_FORM_WIDTH }}
                w="100%"
                minH="100vh"
                maxW="800px"
              >
                <SubmittedFormDetails formResponse={formResponse!} />
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
    </Flex>
  )
}
